<template>
  <div>
      资源不存在！
  </div>
</template>
<script>
export default {
    
}
</script>
<style lang="less" scoped>

</style>